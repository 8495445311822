import { cn } from '../../utils';

export default function LogoType({ className }) {
  return (
    <img
    src="TiC-logo-white.png"
    alt="Thinking into Character"
    className={cn('h-16', className)}
  />
  );
}
